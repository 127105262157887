import {Inject, Injectable, LOCALE_ID} from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import {BehaviorSubject, catchError, filter, Observable, switchMap, take} from "rxjs";
import {AuthedUserService} from "../authed-user.service";
import {UserService} from "./user.service";
import {AuthResponse} from "../../model/responses";
import {NavigationService} from "../ui/navigation.service";

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

    private isRefreshing = false;
    private refreshTokenSubject =
        new BehaviorSubject<AuthResponse | undefined>(undefined);

    constructor(
        private localStorageService: AuthedUserService,
        @Inject(LOCALE_ID) public locale: string,
        private userService: UserService,
        private navigationService: NavigationService
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.method == 'JSONP') {
            return next.handle(request);
        }
        let interceptedRequest = request.clone({
            headers: request.headers.set("Accept-Language", this.locale)
        })
        let auth = this.localStorageService.getAuth()
        if (auth) {
            const now = new Date().getTime()
            const issuedAt = new Date(auth.token.issuedAt).getTime()
            const expiresAt = new Date(auth.token.expiresAt).getTime()
            // Refresh token if it expires in less than 2 hours and was issued less than 40 seconds ago
            if (now + 2 * 3600 * 1000 > expiresAt && now > issuedAt + 40 * 1000) {
                return this.refreshToken(interceptedRequest, next, auth.refreshToken.token)
            }
            interceptedRequest = this.addToken(interceptedRequest, auth.token.token)
        }
        return next.handle(interceptedRequest);
    }

    private addToken(req: HttpRequest<any>, token: string) {
        return req.clone({
            headers: req.headers.set("Authorization", "Bearer " + token)
        });
    }

    private refreshToken(req: HttpRequest<any>, next: HttpHandler, refreshToken: string): Observable<HttpEvent<any>> {
        console.log("Refreshing token (request: " + req.url + ")")
        this.refreshTokenSubject.next(undefined);
        if (this.isRefreshing) {
            // If the refresh token is already being retrieved, wait for it to be retrieved
            if (req.url.includes("users/login/refresh")) {
                // Let the refresh request through
                return next.handle(req);
            }
            // Wait for the refresh token to be retrieved
            return this.refreshTokenSubject.pipe(
                // Wait for the refresh token to be retrieved
                filter(result => result != undefined),
                take(1),
                // When the token is retrieved, add it to the request
                switchMap(auth => {
                    console.log("Handling request to " + req.url + " with new token")
                    return next.handle(this.addToken(req, auth!.token.token))
                })
            )
        } else {
            this.isRefreshing = true;
            console.log("Calling refresh token endpoint")
            // Handle error if the refresh token is invalid
            return this.userService.refreshAuth(refreshToken).pipe(
                switchMap(authResponse => {
                    console.log("Got new token!")
                    this.isRefreshing = false;
                    this.localStorageService.setAuth(authResponse);
                    this.refreshTokenSubject.next(authResponse);
                    return next.handle(this.addToken(req, authResponse.token.token))
                }),
                catchError(error => {
                    console.log("Error refreshing token: " + error.error?.message)
                    console.log(error)
                    this.isRefreshing = false;
                    this.localStorageService.removeAuthInfo();
                    //this.navigationService.navigate(['/login'], true, {
                    //    queryParams: {redirect: decodeURIComponent(this.navigationService.currentPath)}
                    //});
                    return next.handle(req);
                })
            )
        }
    }
}
