import {Injectable} from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import {catchError, throwError, timer, Observable, retry} from 'rxjs';
import {AuthedUserService} from "../authed-user.service";
import {NavigationService} from "../ui/navigation.service";

@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {

  constructor(
    private localStorageService: AuthedUserService,
    private navigationService: NavigationService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      retry({
        count: 30,
        delay: (error, attempts) => {
          if (error.status === 0) {
            return timer(2000); // retry if status was 502
          }
          return throwError(error); // throw error for all other status codes
        },
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status == 401 && request.headers.has('Authorization')) {
          this.localStorageService.removeAuthInfo()
          //if (this.navigationService.currentPath != '/') {
          //  this.navigationService.navigate(['/']);
          //}
        }
        if (error.status != 200) {
          console.log(`API Error (${error.status}): ${error.error?.message}`)
        } else {
          console.error(`API Error (${error.status}):`)
          console.error(error)
        }
        throw error;
      })
    );
  }
}
